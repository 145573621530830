import React, { ChangeEvent, FC, MouseEvent, ReactNode, useEffect, useRef, useState } from "react";
import styles from "./ModalWindow.module.scss";
import Button from "../Button";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { PageSelectors, setModalWindowData } from "../../redux/reducers/pageSlice";
import { ButtonType, ModalWindowTypes } from "../../utils/@globalTypes";
import { CloseIcon } from "../../assets/icons";
import Loader from "../Loader";
import { useClickOutside } from "../../utils/functions";

type ModalWindowProps = {
  title?: string;
  children: ReactNode;
  btnTitle?: string;
  onSubmit?: () => void;
  isValid?: boolean;
  cancelTitle?: string;
  cancelHandler?: () => void;
  windowClassname?: string;
  customBtns?: boolean;
  containerClassname?: string;
  isLoading: boolean;
  hideCross?: boolean;
  isFullCustom?: boolean;
  isCloseOnOverlay?:boolean
};

const ModalWindow: FC<ModalWindowProps> = ({
  title,
  children,
  btnTitle,
  onSubmit,
  isValid,
  cancelHandler,
  cancelTitle,
  windowClassname,
  customBtns,
  containerClassname,
  isLoading,
  hideCross,
  isFullCustom,
  isCloseOnOverlay=true
}) => {
  const dispatch = useDispatch();

  const modalWindowData = useSelector(PageSelectors.getModalWindowData("data"));

  const windowContainerRef = useRef<HTMLDivElement>(null);
  const windowRef = useRef<HTMLDivElement>(null);

  const [visibility, setVisibility] = useState(false);
  const [windowHeight, setWindowHeight] = useState(0);

  const onCancelBtnClick = () => {
    setVisibility(false);
    setTimeout(() => {
      dispatch(setModalWindowData({ type: ModalWindowTypes.CLOSE }));
    }, 300);
  };

  const onOverlayClick = (e: any) => {
    if (windowRef.current && !windowRef.current.contains(e.target) &&isCloseOnOverlay) {
      onCancelBtnClick();
    }
  };

  useEffect(() => {
    setVisibility(true);
    document.body.style.overflow = "hidden";
    return () => {
      setVisibility(false);
      document.body.style.overflow = "auto";
    };
  }, []);

  useEffect(() => {
    if (windowRef.current) {
      setWindowHeight(windowRef.current.getBoundingClientRect().height);
    }
  }, []);

  useEffect(() => {
    return () => {
      modalWindowData && dispatch(setModalWindowData({ type: null, data: null }));
    };
  }, [modalWindowData]);

  return (
    <div
      className={classNames(styles.container, containerClassname, {
        [styles.notCustomContainer]: !isFullCustom,
        [styles.showOverlay]: visibility,
      })}
      ref={windowContainerRef}
      onClick={onOverlayClick}
    >
      <div>
        <div
          className={classNames(styles.window, windowClassname, {
            [styles.showWindow]: visibility,
            [styles.notCustom]: !isFullCustom,
          })}
          style={{ minHeight: `${windowHeight}px` }}
          ref={windowRef}
        >
          {!hideCross && (
            <div className={styles.closeBtn} onClick={onCancelBtnClick}>
              <CloseIcon />
            </div>
          )}
          {!isLoading ? (
            isFullCustom ? (
              children
            ) : (
              <>
                <h2 className={styles.title}>{title}</h2>
                <div className={styles.column} style={{ gap: !customBtns ? "20px" : undefined }}>
                  {children}
                </div>
                {!customBtns && (
                  <div className={styles.btnWrapper}>
                    <Button
                      title={btnTitle || "Сохранить"}
                      type={ButtonType.PRIMARY_SMALL}
                      onClick={onSubmit}
                      disabled={isValid}
                    />
                    <Button
                      title={cancelTitle || "Отмена"}
                      type={ButtonType.SECONDARY_SMALL}
                      onClick={cancelHandler || onCancelBtnClick}
                    />
                  </div>
                )}
              </>
            )
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </div>
  );
};

export default ModalWindow;
