import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

import {   
  BookingType,  
  UpdateBookingType,  
  MainObjectShort,  
  UpdateBookingPayloadData
} from "../types/bookingTypes";

type BookingState = {
  bookingList: BookingType[];
  chessObjectsList: MainObjectShort[] | null;
  isTelephoneValid:boolean;
};

const initialState: BookingState = {
  bookingList: [],
  chessObjectsList: null,
  isTelephoneValid:false
};

const BookingSlice = createSlice({
  name: "booking",
  initialState,
  reducers: {
    getBookingList(_, __) {},
    setBookingList(state, action: PayloadAction<BookingType[]>) {
      state.bookingList = action.payload;
    },
    getChessObjects(_, __) {},
    setChessObjectsList(state, action: PayloadAction<MainObjectShort[] | null>) {
      state.chessObjectsList = action.payload;
    },
    updateCurrentBooking(_, __: PayloadAction<UpdateBookingPayloadData>) {},
    removeBooking(_, __: PayloadAction<number>) {},
    removeBookingFromList(state, action: PayloadAction<number>) {
      state.bookingList = state.bookingList.filter((note) => note.id !== action.payload);
    },
    createBooking(_, __: PayloadAction<UpdateBookingType>) {},
    setIsTelephoneValid(state, action: PayloadAction<boolean>) {
      state.isTelephoneValid= action.payload
    },
}});

export const {
  getBookingList,
  setBookingList,
  getChessObjects,
  setChessObjectsList,
  updateCurrentBooking,
  removeBooking,
  removeBookingFromList,
  createBooking,
  setIsTelephoneValid
} = BookingSlice.actions;

export default BookingSlice.reducer;

export const bookingName = BookingSlice.name;

export const BookingSelectors = {
  getBookingList: (state: RootState) => state.booking.bookingList,
  getChessObjects: (state: RootState) => state.booking.chessObjectsList,
  getIsTelephoneValid: (state: RootState) => state.booking.isTelephoneValid
};
