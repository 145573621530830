import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setModalWindowData, setPageData } from "../../../redux/reducers/pageSlice";
import moment from "moment";
import 'moment/locale/ru';
import classNames from "classnames";
import Calendar from "../../../components/Calendar";
import Button from "../../../components/Button";
import { ButtonType, ModalWindowTypes } from "../../../utils/@globalTypes";
import CheckerboardTable from "../../../components/CheckerboardTable";
import { 
  ArrowSingle, 
  ArrowDouble  
} from "../../../assets/icons";
import styles from "./Checkerboard.module.scss";
import { BookingSelectors } from "../../../redux/reducers/bookingSlice";
import HelpPanel from "../HelpPanel";
import useWindowDimensions from "../../../utils/useWindowDimensions";

const Checkerboard = () => {
  const dispatch = useDispatch();
  moment.locale('ru');

  
  const handlerNewBooking=()=>{
    dispatch(setModalWindowData({type: ModalWindowTypes.CREATE_RESERVATION}))    
  }
  const objects = useSelector(BookingSelectors.getChessObjects);
  
  const today= moment().startOf('day');
  const startDate=today.clone().subtract(6,'month')
  const endDate=today.clone().add(12,'month')

  const createPeriod =(today:moment.Moment)=> {
    
    const period:moment.Moment[]=[]
        
    for(let i=startDate; i<=endDate; i=i.clone().add(1,"day")){      
      period.push(i)
    }
    return period
  }
  const period= useMemo(()=>createPeriod(today),[today])
  // const { width } = useWindowDimensions();
  const [itemWidth, setItemWidth] = useState<number>(100);   // Объявляем ширину ячейки таблицы, используется в расчетах и стилях
  // useEffect(()=>{
  //   if(width>932){
  //     setItemWidth(100)
  //   } else if(width>600){
  //     setItemWidth(64)
  //   } else setItemWidth(32)
  // },[width])
 
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [initialDay, setInitialDay]=useState(today);
  const [scrollPosition, setScrollPosition] = useState<number>(180*itemWidth);
  const [isForceUpdateScroll, setIsForceUpdateScroll] = useState<boolean>(false)   //принудительный update скролла, если дата на календаре не менялась и надо к ней вернуться

  const minDate = startDate.toDate();  //ограничительные даты для календаря
  const maxDate = endDate.toDate();
 
 const updateScrollForDate = (date:moment.Moment) => {          //обновление скролла после выбора дат на календаре
    const indexDate = period.findIndex((item)=>item.startOf('day').unix()===date.startOf('day').unix())
    const scroll = (indexDate*itemWidth) - itemWidth*2
    setScrollPosition(scroll)
  }
  useEffect(()=>{
    updateScrollForDate(initialDay)
    setSelectedDate(initialDay.toDate())
  },[initialDay])

  const handlerPrevMonth=()=>{
    setInitialDay(prevState=> {
      return(
      prevState.clone().subtract(1, 'month')
      )}
    )
    setIsForceUpdateScroll(false)
  }

  const handlerNextMonth=()=>{
    setInitialDay(prevState=> {
      return(
      prevState.clone().add(1, 'month')
      )}
    )
    setIsForceUpdateScroll(false)
  }

  const handlerPrevWeek=()=>{
    setInitialDay(prevState=> {
      return(
      prevState.clone().subtract(7, 'day')
      )}
    )
    setIsForceUpdateScroll(false)
  }

  const handlerNextWeek=()=>{
    setInitialDay(prevState=> {
      return(
      prevState.clone().add(7, 'day')
    )}
  )
  setIsForceUpdateScroll(false)
}

  const handlerToday=()=>{
    setInitialDay(today)
    setIsForceUpdateScroll(true)    
  }
  const closeNewBooking=()=>{
    dispatch(setModalWindowData({type: ModalWindowTypes.CREATE_RESERVATION}))   
  }

  const handlerDatepicker =(date:Date)=> {
    if (date instanceof Date){
      
    setSelectedDate(date);
    setInitialDay(moment(date))

    }else{
      setSelectedDate(new Date());
      setInitialDay(today)
    }
  }
  
  const cancelForceUpdate = ()=> {
    setIsForceUpdateScroll(false)
  }

  return (
    <section className={styles.container}>
      <div className={styles.helpMob}>
        <HelpPanel/>
      </div>
      <div className={styles.buttonPanel}>
        <div className={styles.leftWrapper}>
          <div className={styles.calendarWrap}>
            <div className={styles.calendarButton}> 
              <div className={styles.prev} onClick={handlerPrevMonth}>
                <ArrowDouble/>
                <div className={styles.help}>Предыдущий месяц</div>
              </div>            
              <div className={styles.prev} onClick={handlerPrevWeek}>
                <ArrowSingle/>
                <div className={styles.help}>Предыдущая неделя</div>              
              </div>
            </div>
            <div className={styles.calendar}>
              <Calendar 
                selectedDate={selectedDate}
                onChange={handlerDatepicker}
                minDate={minDate}
                maxDate={maxDate}
                placeholderText="Введите дату"                
              />
            </div>
            <div className={styles.calendarButton}>
              <div className={styles.next} onClick={handlerNextWeek}>
                <ArrowSingle/>
                <div className={styles.help}>Следующая неделя</div>
              </div>
              <div className={styles.next} onClick={handlerNextMonth}>
                <ArrowDouble/>
                <div className={styles.help}>Следующий месяц</div>
              </div>
            </div>
          </div>
          <div className={styles.todayDesk}>
            <Button
              title="Сегодня"
              onClick={handlerToday}
              type={ButtonType.PRIMARY_SMALL}
              className={styles.todayButton}
            />
          </div>
          <div className={styles.todayMobile}>
            <div className={styles.todayWrap}>
              <div className={styles.calendarButton}> 
                <div className={styles.prev} onClick={handlerPrevMonth}>
                  <ArrowDouble/>
                  <div className={styles.help}>Предыдущий месяц</div>
                </div>            
                <div className={styles.prev} onClick={handlerPrevWeek}>
                  <ArrowSingle/>
                  <div className={styles.help}>Предыдущая неделя</div>              
                </div>
              </div>
              <Button
                title="Сегодня"
                onClick={handlerToday}
                type={ButtonType.SECONDARY_SMALL}
                className={styles.todayButton}
              />
              <div className={styles.calendarButton}> 

                <div className={styles.next} onClick={handlerNextWeek}>
                  <ArrowSingle/>
                  <div className={styles.help}>Следующая неделя</div>
                </div>
                <div className={styles.next} onClick={handlerNextMonth}>
                  <ArrowDouble/>
                  <div className={styles.help}>Следующий месяц</div>
                </div>
              </div>
            </div>
          </div>
        </div>       
        <div className={styles.helpDesc}>
          <HelpPanel/>
        </div>
        <div className={styles.rightWrapper}>
          <div className={styles.desctop}>      
            <Button
              title="Новая бронь"
              onClick={handlerNewBooking}
              type={ButtonType.PRIMARY_SMALL}
              className={styles.bookingButton}
            />
          </div>  
          <div className={styles.mobile}>      
            <Button
              title="+"
              onClick={handlerNewBooking}
              type={ButtonType.PRIMARY_SMALL}
              className={styles.mobile}              
            />
          </div>  
        </div>        
      </div>
      {/* <div className={styles.describe}>Отображаются брони со статусами: "подтверждена" и "ожидает подтверждения"</div> */}
      {objects&&<CheckerboardTable 
        period={period} 
        today={today} 
        scrollPosition ={scrollPosition} 
        itemWidth={itemWidth} 
        isForceUpdate={isForceUpdateScroll}
        cancelForce = {cancelForceUpdate}
        objects= {objects}
        selectedDate = {selectedDate}
      />}
    </section>
  );
};

export default Checkerboard;
